@import '../../styles/variables.scss';

footer {
    background-color: #f5f5f5;
    padding: 15px 0;
    text-align: center;
    font-size: 14px;
    color: #999;
    border-top: 1px solid #e5e5e5;
    // margin-top: 20px;
    height: $footer-height;
    // position: fixed;
    width: 100%;
    bottom: 0;
}