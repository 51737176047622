:fullscreen {
  img.image-gallery-image {
    object-fit: contain !important;
  }
}

.accommodation-card {
  overflow: hidden;

  > div:first-child {
    height: inherit;
  }
}
