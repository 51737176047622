@import "../../styles/variables.scss";

$gap: 2px;
$grid-height: 400px;

.grid-images {
  height: $grid-height;
  gap: $gap;
  max-height: $grid-height;

  .grid-image-big {
    height: $grid-height;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .smaller-grid-images {
    height: calc(#{$grid-height}/ 2 - #{$gap}/ 2);
    cursor: pointer;

    img {
      height: 100%;
      object-fit: cover;
    }
  }
}

.skeleton-grid-smaller-images {
  height: calc(#{$grid-height}/ 2 - #{$gap}/ 2);
}

.accommodation-image-gallery {
  margin: 0 -#{$main-layout-padding};

  &.full-screen {
    overflow: hidden !important;
    margin: unset !important;
  }
  .image-gallery-image {
    object-fit: cover !important;
    height: 300px !important;

    @media screen and (min-width: 768px) {
      height: 80vh !important;
    }
  }
}

.image-gallery-svg {
  height: 80px !important;
  width: 30px !important;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 100px;
  object-fit: cover;
}

.room-pill {
  text-wrap: wrap !important;
}