.PhoneInputInput {
  background: inherit !important;
  width: 100% !important;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.PhoneInputInput:focus {
  //border-color: #3182ce;
  //box-shadow: 0 0 0 1px #3182ce;
}
