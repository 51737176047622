.room-gallery {
  .room-gallery-image > img {
    height: 200px !important;
    object-fit: cover;
  }

  &.full-screen {
    //z-index: 10;
    //overflow-y: hidden;
    overflow: hidden !important;
    margin: unset !important;
    object-fit: cover !important;
    height: 300px !important;
    @media screen and (min-width: 768px) {
      height: 80vh !important;
    }
  }
  .image-gallery-image {
    object-fit: cover !important;
    height: 300px !important;

    @media screen and (min-width: 768px) {
      height: 80vh !important;
    }
  }

  &:not(.full-screen) .image-gallery-slides {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
}
