.b-deselect {
    padding-right: 15px !important;
    padding-left: 15px !important;
}
.rmdp-wrapper {
    width: 100%;
}
.b-date::before {
    content: "×"; /* The "X" character for the cross */
    font-size: 24px; /* Adjust the size of the "X" */
    color: red; /* Set the color of the "X" */
    position: absolute; /* Absolute positioning within the div */
    top: 50%; /* Vertically center the cross */
    left: 0; /* Position the cross at the left edge */
    transform: translateY(-50%); /* Adjust the vertical positioning to perfectly center */
    cursor: pointer; /* Change the cursor to pointer when hovering */
}
.rmdp-range:has(.reserved) {
    background-color: #8fb4f9;
}

.rmdp-range:has(.in-service) {
    background-color: red;
}

div.reserved {
    background-color: #8fb4f9 !important;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

div.in-service {
    background-color: red !important;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.un-availble {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.un-availble p {
    margin: 0;
}

