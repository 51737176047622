$breakpoint-tablet: 768px;
$breakpoint-mobile: 420px;
$card-layout-breakpoint: 648px;

:fullscreen {
  img.image-gallery-image {
    object-fit: contain !important;
  }
}

.vehicle-gallery {
  width: 300px;

  @media (max-width: $breakpoint-mobile) {
    width: 300px;
  }

  @media (min-width: $breakpoint-tablet) {
    width: 400px;
  }

  .vehicle-gallery-image > img {
    height: 150px !important;
    //object-fit: cover;
  }

  &.full-screen {
    //z-index: 10;
    //overflow-y: hidden;
    overflow: hidden !important;
    margin: unset !important;
    object-fit: contain !important;
  }

  .image-gallery-image {
    object-fit: cover !important;
    height: 300px !important;

    @media screen and (min-width: 768px) {
      height: 80vh !important;
    }
  }

  &:not(.full-screen) .image-gallery-slides {
    border-radius: 8px;
  }
}
